import { useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Space,
  Tabs,
  Select,
  Image,
  Tooltip,
  Button,
  Layout,
  Dropdown,
  Checkbox,
  Row,
  Col,
} from "antd";
import { channels } from "../../constants/enums";
import { mode } from "../../constants/env";
import { useNavigate } from "react-router-dom";
import { MdOutlineAnalytics } from "react-icons/md";
import ConversationCharts from "./conversations-charts/ConversationsCharts";
import ResponsesCharts from "./responses-charts/ResponsesCharts";
import BroadcastCharts from "./broadcast-charts/BroadcastCharts";
import { RiFilterOffLine } from "react-icons/ri";
import ContactCharts from "./contacts-charts/ContactCharts";
import KnowledgeAreaCharts from "./knowledge-area-charts/KnowledgeAreaCharts";
import AccessDenied from "../../shared-components/AccessDenied";
import ModuleNotSubscribed from "../../shared-components/ModuleNotSubscribed";
import { DownOutlined } from "@ant-design/icons";
import TicketsCharts from "./tickets-charts/TicketsCharts";

const { Option } = Select;

const Analytics = (props) => {
  const [state, dispatchReducer] = useGlobalContext();

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [filterForCharts, setFilterForCharts] = useState({
    filterByDate: "thirtydays",
    filterByChannel: undefined,
  });
  const [enableClear, setEnableClear] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const tabPane = [
    {
      key: "1",
      label: (
        <Space size={15}>
          <MdOutlineAnalytics size={18} style={{ margin: -4 }} />
          Responses
        </Space>
      ),
      children: <ResponsesCharts />,
    },
    {
      key: "2",
      label: (
        <Space size={15}>
          <MdOutlineAnalytics size={18} style={{ margin: -4 }} />
          Conversations
        </Space>
      ),
      children: <ConversationCharts />,
    },
    {
      key: "3",
      label: (
        <Space size={15}>
          <MdOutlineAnalytics size={18} style={{ margin: -4 }} />
          Broadcast
        </Space>
      ),
      children: <BroadcastCharts />,
    },
    ["DEV", "STAGING"].includes(mode) && {
      key: "4",
      label: (
        <Space size={15}>
          <MdOutlineAnalytics size={18} style={{ margin: -4 }} />
          Contacts
        </Space>
      ),
      children: <ContactCharts />,
    },
    {
      key: "5",
      label: (
        <Space size={15}>
          <MdOutlineAnalytics size={18} style={{ margin: -4 }} />
          Knowledge Area
        </Space>
      ),
      children: <KnowledgeAreaCharts />,
    },
    ["DEV", "STAGING"].includes(mode) && {
      key: "6",
      label: (
        <Space size={15}>
          <MdOutlineAnalytics size={18} style={{ margin: -4 }} />
          Tickets
        </Space>
      ),
      children: <TicketsCharts />,
    },
  ];

  const handleTabChange = (key) => {
    switch (key) {
      case "1":
        navigate("/analytics/responses-charts");
        break;
      case "2":
        navigate("/analytics/conversation-charts");
        break;
      case "3":
        navigate("/analytics/broadcast-charts");
        break;
      case "4":
        navigate("/analytics/contact-charts");
        break;
      case "5":
        navigate("/analytics/knowledge-area-charts");
        break;
      case "6":
        navigate("/analytics/tickets-charts");
        break;
      default:
        navigate("/analytics/responses-charts");
    }
  };

  const filterFunctionality = (filterByDate, filterByChannel) => {
    dispatchReducer({
      type: "RD_SET_FILTER_BY_DATE",
      payload: { filterByDateForCharts: filterByDate },
    });
    dispatchReducer({
      type: "RD_SET_FILTER_BY_CHANNEL",
      payload: { filterByChannelForCharts: filterByChannel },
    });
  };

  const clearFilter = (date, channel) => {
    setFilterForCharts({ filterByDate: date, filterByChannel: channel });
    filterFunctionality(date, channel);
  };

  const handleCheckboxChange = (channel) => {
    setSelectedChannels((prevSelectedChannels) => {
      if (prevSelectedChannels && prevSelectedChannels.includes(channel)) {
        return prevSelectedChannels.filter(
          (previousChannel) => previousChannel !== channel
        );
      } else {
        return [...prevSelectedChannels, channel];
      }
    });
  };

  const handleButtonClick = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  useEffect(() => {
    setActiveTab(props.tab.toString());
  }, [props]);

  useEffect(() => {
    if (
      filterForCharts.filterByDate !== undefined ||
      filterForCharts.filterByChannel !== undefined
    ) {
      filterFunctionality(
        filterForCharts.filterByDate,
        filterForCharts.filterByChannel
      );
    }
  }, [filterForCharts.filterByDate, filterForCharts.filterByChannel]);

  useEffect(() => {
    clearFilter("thirtydays", undefined);
  }, [state.application.botInAction]);

  return (
    <>
      {!state.currentAccount.account.modules.analytics ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModuleNotSubscribed module="analytics" />
        </Layout.Content>
      ) : !state.currentUser.permission.ANALYTICS_VIEW ? (
        <AccessDenied />
      ) : (
        <Tabs
          onChange={handleTabChange}
          type="card"
          destroyInactiveTabPane={true}
          activeKey={activeTab}
          items={tabPane}
          tabBarExtraContent={
            <>
              <Dropdown
                trigger={["click"]}
                open={dropdownVisible}
                onOpenChange={(visible) => setDropdownVisible(visible)}
                dropdownRender={() => (
                  <div
                    style={{
                      maxHeight: 300,
                      maxWidth: 300,
                      borderRadius: 5,
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      background: "white",
                      padding: 12,
                    }}
                  >
                    <Row gutter={[0, 12]}>
                      <Col
                        span={24}
                        style={{
                          maxHeight: "230px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {Object.keys(channels)
                          .filter(
                            (channel) =>
                              channel !== "WEBCHAT" && channel !== "GBP"
                          )
                          .map((channel) => {
                            return (
                              <Row key={channel}>
                                <Space
                                  style={{
                                    marginBottom: 8,
                                  }}
                                  size={11}
                                >
                                  <Checkbox
                                    onChange={() =>
                                      handleCheckboxChange(channel)
                                    }
                                    checked={selectedChannels?.includes(
                                      channel
                                    )}
                                  />
                                  <Image
                                    src={channels[channel].logo}
                                    width={17}
                                    height={17}
                                    preview={false}
                                  />

                                  {channels[channel].displayName}
                                </Space>
                              </Row>
                            );
                          })}
                      </Col>

                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          disabled={selectedChannels?.length === 0}
                          onClick={() => {
                            setSelectedChannels([]);
                            setFilterForCharts({
                              filterByChannel: [],
                              filterByDate:
                                filterForCharts?.filterByDate || "thirtydays",
                            });
                            filterFunctionality("", []);
                            setDropdownVisible(false);
                          }}
                        >
                          Reset
                        </Button>
                        <Button
                          style={{ marginRight: "8px" }}
                          disabled={selectedChannels?.length === 0}
                          onClick={() => {
                            setFilterForCharts({
                              ...filterForCharts,
                              filterByChannel: selectedChannels,
                            });
                            setDropdownVisible(false);
                          }}
                          type="primary"
                        >
                          OK
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              >
                <Button
                  onClick={handleButtonClick}
                  style={{ width: "180px", marginRight: "5px" }}
                >
                  {selectedChannels?.length ? (
                    <span style={{ fontWeight: "bold" }}>
                      {channels[selectedChannels[0]].displayName}
                      {selectedChannels.length > 1 &&
                        ` ... (+${selectedChannels.length - 1})`}
                    </span>
                  ) : (
                    "Select Channels"
                  )}
                  <DownOutlined />
                </Button>
              </Dropdown>

              <Select
                allowClear={enableClear}
                value={filterForCharts.filterByDate}
                onClear={() => {
                  setEnableClear(false);
                  clearFilter("thirtydays", filterForCharts.filterByChannel);
                }}
                placeholder="Select date range"
                defaultValue={filterForCharts.filterByDate}
                style={{ marginRight: "10px", width: "150px" }}
                onSelect={(key) => {
                  setEnableClear(true);
                  setFilterForCharts({ ...filterForCharts, filterByDate: key });
                }}
              >
                <Option value="today">Today</Option>
                <Option value="sevendays">Last 7 days</Option>
                <Option value="thirtydays">Last 30 days</Option>
                <Option value="sixtydays">Last 60 days</Option>
                <Option value="ninteydays">Last 90 days</Option>
              </Select>
              <Tooltip
                placement="topRight"
                title="Clear filter(s)"
                style={{ marginRight: "10px" }}
              >
                <Button
                  disabled={
                    (filterForCharts.filterByDate && enableClear) ||
                    filterForCharts.filterByChannel
                      ? false
                      : true
                  }
                  icon={<RiFilterOffLine style={{ margin: -2 }} />}
                  onClick={() => {
                    setEnableClear(false);
                    clearFilter("thirtydays", undefined);
                    setSelectedChannels([]); // Clear selected channels
                  }}
                  style={{
                    marginTop: "-5px",
                    verticalAlign: "-1px",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </>
          }
        />
      )}
    </>
  );
};

export default Analytics;
